import React, {
    ReactElement,
    useEffect,
} from 'react';
import PageTemplate from 'page_template';
import HeaderCard from 'components/header_card';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';
import * as PATHS from 'routing/paths';

import {
    Container,
} from '../resources_page/styles/resources_page.style';
import {
    Innerr,
    Heading1,
    Heading2,
    Heading3,
    Heading4,
    Heading5,
    Heading6,
    SummaryText,
    BreakLine,
    SummaryTextCenter,
    Image,
    ImgCaption,
} from './styles/reunion_pages.style';

const WHITE = '#FFFFFF';
const CLARKSON_GREEN = '#004F42';
const CLARKSON_YELLOW = '#F3D200';

export default function ReunionLandingPage() {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <PageTemplate active="home" reunion={true}>
            <HeaderCard bkgSrc="/images/headers/Leaves.jpg">
                <HeaderCard.Title>Honors Reunion 2025</HeaderCard.Title>
            </HeaderCard>
            <Container>
                <Innerr>
                    <Heading1>Clarkson University Honors Program Alumni Reunion Weekend</Heading1>
                    <BreakLine />
                    <SummaryTextCenter>
                        Join to stay updated and find out more about the updates to the Clarkson University Honors Program!
                    </SummaryTextCenter>
                    <BreakLine />
                    <Heading2>June 20-22, 2025</Heading2>
                    <Heading3>Cost Information:</Heading3>
                    <SummaryText>
                        <p>We tried hard to keep the prices reasonable!</p>
                        <ul>
                            <li><b>Honors All-In Family Dinner | </b>$40 per person</li>
                            <li><b>Honors Program Ice Cream Social | </b>$10 per person</li>
                            <ul>
                                <li><i>You will be asked to share your favorite flavor during registration.</i></li>
                            </ul>
                            <li><b>Children under 15 are free</b> - Just please give us a headcount in the notes during registration so enough food is ordered!</li>
                        </ul>
                    </SummaryText>
                    <Image src='/images/reunion/Honors Group and Team Building_2010.JPG' />
                    <ImgCaption>Honors Team Building - August 2010</ImgCaption>
                    <BreakLine />
                    <Heading3>Meal Information:</Heading3>
                    <SummaryText>
                        <p>Please refer to the Schedule Page for more information on the menus offered.</p>
                        {/* <p>Please refer to the <a href={PATHS.REUNION_SCH} style={{ color: CLARKSON_GREEN }}>Schedule Page</a> for more information on the menus offered.</p> */}
                    </SummaryText>
                    <Image src='/images/reunion/Honors Code Crackers_2005.jpg' />
                    <ImgCaption>Honors Code Crackers - November 2005</ImgCaption>
                    <BreakLine />
                    <Heading2>More details to come!</Heading2>
                    <BreakLine />
                    <SummaryTextCenter><i>Issues with any photos? Feel free to send an email at webmaster@clarksonhonors.org with specific information and requests.</i></SummaryTextCenter>
                </Innerr>
            </Container>
        </PageTemplate>
    );
}